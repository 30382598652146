@use "../partials/vars";

.campaign__results{
    /* .campaign__results-bar{
        padding: 5px;
        display: flex;
        flex-direction: row;
    } */
    .campaign__results-bar{
        background: var(--light);
        margin: 5px;
        padding: 0px;
        width: 100%;

        .campaign__results-listItem{
            width: 100%;
            height: auto;
            min-height: 90px;
            padding-top: 20px;
            padding-bottom: 20px;
            margin: 5px 0px;
            background-color: var(--light);
            border: 2px solid RGBA(var(--textColor-rgb) / 10%);
            color: var(--textColor);
            font-weight: 550;
            text-align: center;
            font-size: 12px;
            cursor: pointer;
            position: relative;
            border-radius: var(--radius);
            word-break: break-word;;
        
            .campaign__results-listItemNumber {
                position: absolute;
                left: -1px;
                top: -2px;
                background: var(--secondary);
                font-size: 12px;
                padding: 3px 10px;
                border-bottom: 1px dashed var(--primary);
                border-right: 1px dashed var(--primary);
            }
            .download__results{
                position: absolute;
                right: 0px;
                top: 0px;
                z-index: 99;
            }
        }
        .campaign__results-listItemActive{
            border: 2px solid var(--primary);
                background: RGBA(var(--primary-rgb)/ 20%);
                color: var(--primary);
                box-shadow: 0px 3px 6px 1px RGB(var(--primary-rgb) / 30%);
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
        }
    }
    .campaign__results-content{
        margin: auto;
        width: 1500px;
        max-width: 100%;
        padding: 10px;

        .inner{
            background: var(--light);
            height: 82vh;
            padding: 10px;
            overflow-y: auto;
            width: 100%;
            overflow-x: auto;
            position: relative;
        }
        .table__cell-option{
            width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.contain__general-popularity{
    width: 600px;
    max-width: 100%;
    margin: auto;

    .bar__chart{
        margin-top: 10px;
    }
}
.bar__chart{
    width: 100%;
    display: flex;
    flex-direction: column;
    background: RGB(var(--primary-rgb) / 30%);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;

    .bar__chart-title{
        font-size: 22px;
        font-weight: bold;
        background: RGBA(var(--primary-rgb) / 20%);
        padding: 0px 4px;
        text-align: center;;
    }
    .bar__chart-bar{
        width: 0%;
        background: var(--primary);
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        text-align: center;
        color: var(--secondaryTextColor);
        transition: width 1s;

        .bar__chart-percentage{
            background: var(--primary);
            border-radius: 4px;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            text-align: center;
            padding-top: 13px;
            padding-bottom: 13px;
            transition: width 1s;
        }
    }
}
.poll__results{
    width: 100%;
    position: relative;

    .poll__results-container {
        width: 1000px;
        max-width: 100%;
        margin: auto;
        margin-top: 30px;
        
        .poll__results-containerInner {
            border-left: 1px solid RGBA(var(--textColor-rgb) / 20%);
            width: 100%;
            background-color: var(--light);
            padding-top: 20px;
            padding-bottom: 20px;
            border-radius: var(--radius);

            .poll__result {
                width: 100%;
                display: flex;
                flex-direction: column;
                text-align: left;
                margin-top: 25px;

                .poll__result-text {
                    margin-left: 10px;
                }
            }
        }

        .poll__results-totalVotes {
            position: absolute;
            right: 15px;
            top: 0px;
            font-size: 35px;

            .material-icons{
                font-size: 45px;
                vertical-align: middle;
            }
        }
    }
    .bar__chart {
        position: relative;

        .bar__chart-bar {
            position: relative;

            .bar__chart-pollVotes {
                position: absolute;
                bottom: -25px;
                right: 5px;
                color: RGBA(var(--textColor-rgb) / 50%);
            }
        }

    }
    .image__choice-inner{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        overflow: hidden;

        .image__choice__result{
            flex-grow: 1;
            min-width: 350px;
            margin-top: 10px;
            margin-left: 10px;
            border: 1px RGBA(var(--textColor-rgb) / 10%);
            background-color: var(--light);
            border-radius: var(--radius);
            padding: 10px;
            padding-bottom: 0px;

            .bar__chart{
                margin-left: -10px;
                margin-bottom: -5px;
                transition: width 1s;
                width: 100%;
                
                .bar__chart-percentage {
                    transition: width 1s;
                }
            }

            .image__choice-text{
                flex-basis: calc(100% - 150px);
                height: 50px;
                overflow: hidden;
            }

            .image__choice-image{
                display: flex;
                flex-direction: column;
                justify-content: center;
                img{
                    max-height: 250px;
                }
            }
        }
    }
    @media(max-width: 768px){
        padding-top: 70px;

        .tabs{
            .tabs__item{
                font-size: 13px !important;
            }
        }
    }
}
.prize__card-wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .prize__card{
        flex-basis: 200px;
        height: 150px;
        padding: 10px;
        margin: 5px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: var(--light);
        position: relative;

        .prize__card-label{
            width: 190px;
            white-space: nowrap;
            overflow: hidden;
            position: relative;
            margin-bottom: 10px;
        }
        .prize__card-label::after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            width: 30px; /* Set the width of the fade effect */
            height: 100%;
            background: linear-gradient(to right, RGBA(var(--light-rgb) / 10%), var(--light)); /* Create a fade effect from transparent to white */
        }
        .prize__card-count{
            position: absolute;
            bottom: 0px;
            left: 10px;
        }
        .bar__wrapper{
            position: absolute;
            display: inline-block;
            bottom: 0;
            right: 5px;
            background: RGBA(var(--light-rgb) / 10%);
            width: 50px;
            height: 70%;
            text-align: center;

            .bar {
                position: absolute;
                bottom: 0;
                left: 0;
                display: inline-block;
                background: var(--textColor);
                width: 50px;
                text-align: center;
                z-index: 2;

                label{
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: -20px;
                    font-size: 12px;
                }
            }
        }
    }
    .prize__card-primary{
        background-color: var(--primary);
    }
    .prize__card-primaryLight{
        background-color: RGBA(var(--primary-rgb) / 50%);
    }
}

.results__item-text{
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.total__results{
    position: absolute;
    right: 15px;
    top: 0px;
    font-size: 35px;
    text-align: center;

    .material-icons{
        font-size: 45px;
        vertical-align: middle;
    }
}
.registration__results{
    input{
        width: 250px !important;
    }
    @media(max-width: 400px){
        .inner{
            padding-top: 70px !important;
        }
        input{
            width: 100% !important;
        }
    }
}
.numericPrediction__results{
    position: relative;

    .numericPrediction__results-detailed{
        background: var(--light);
        padding: 10px;
        overflow-y: auto;
        width: 100%;
        overflow-x: auto;

        .numericPrediction__results-detailedActions{
            display: flex;
            flex-direction: row;

            .item{
                width: 50%;

                input[type="text"]{
                    width: 400px;
                    max-width: 100%;
                }
            }
        }
    }
    @media(max-width: 768px){
        padding-top: 70px;

        .tabs{
            .tabs__item{
                font-size: 13px !important;
            }
        }
        .numericPrediction__results-detailedActions{
            flex-wrap: wrap;

            .item{
                flex-basis: 100%;

            }
        }
    }
    @media(max-width: 400px){
        .inner{
            padding-top: 70px !important;
        }
    }
}
.responsiveTable{
    .pivoted{
        margin-left: calc(-50%);
    }
    .tdBefore{
        display: none !important;
    }
}