@use "../partials/vars";
.CampaignModeration {
	/* Using static size and no layout so it will not fail for more than 10000 items */
	.CampaignModeration__item {
		position: relative;
		display: inline-block;
		overflow: hidden;
		padding: 5px;
		margin: 5px;
		height: 200px;
		width: 200px;
		background: var(--light);
		border-radius: var(--radius);
		> div {
			height: 100%;
			width: 100%;
			display: flex;
			overflow: hidden;
			flex-direction: column;
			gap: 10px;
			border: 1px solid transparent;
		}

		.CampaignModeration__itemImage {
			flex: 1;
			overflow: hidden;
			text-align: center;
			img{
				max-width: 100%;
				max-height: 100%;
			}
		}
		.CampaignModeration__itemCaption {
			flex: 1;
			overflow: hidden;
		}
		.CampaignModeration__itemFooter {
			display: grid;
			grid-template-columns: 1fr auto;
			gap: 10px;
		}
		.CampaignModeration__itemDisplayName {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.CampaignModeration__itemTimestamp {
			text-align: right;
		}
		.CampaignModeration__itemControls {
			display: none;
			width: 100%;
			height: 0px;
			overflow: visible;
			text-align: center;
		}

		&.accepted {
			background: var(--success);
		}
		&.unknown {
		}
		&.rejected {
			background: var(--danger);
		}
		&:hover {
			border-color: var(--primary);
			.CampaignModeration__itemControls{
				display: block;
				position: absolute;
				top: var(--radius);
				right: var(--radius);
			}
		}
	}
}
