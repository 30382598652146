@import "vars";

.loader__overlay {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	font-size: 15px;
	color: var(--textColor);
	background-color: RGB(var(--light-rgb) / 75%);
	-webkit-backdrop-filter: blur(4px);
	backdrop-filter: blur(4px);
	opacity: 1;
	visibility: visible;
	backface-visibility: hidden;
}

/* From uiverse.io by @abrahamcalsin */
.jelly-triangle {
	--uib-size: 4rem;
	--uib-speed: 1.75s;
	--uib-color: var(--primary);
	position: relative;
	height: var(--uib-size);
	width: var(--uib-size);
	filter: url('#uib-jelly-triangle-ooze');
}

.jelly-triangle__dot,
.jelly-triangle::before,
.jelly-triangle::after {
	content: '';
	position: absolute;
	width: 33%;
	height: 33%;
	background: var(--uib-color);
	border-radius: 100%;
	box-shadow: 0 0 20px RGBA(var(--primary) / 20%);
}

.jelly-triangle__dot {
	top: 6%;
	left: 30%;
	animation: grow7132 var(--uib-speed) ease infinite;
}

.jelly-triangle::before {
	bottom: 6%;
	right: 0;
	animation: grow7132 var(--uib-speed) ease calc(var(--uib-speed) * -0.666)
	infinite;
}

.jelly-triangle::after {
	bottom: 6%;
	left: 0;
	animation: grow7132 var(--uib-speed) ease calc(var(--uib-speed) * -0.333)
	infinite;
}

.jelly-triangle__traveler {
	position: absolute;
	top: 6%;
	left: 30%;
	width: 33%;
	height: 33%;
	background: var(--uib-color);
	border-radius: 100%;
	animation: triangulate6214 var(--uib-speed) ease infinite;
}

.jelly-maker {
	width: 0;
	height: 0;
	position: absolute;
}

@keyframes triangulate6214 {
	0%,
	100% {
		transform: none;
	}

	33.333% {
		transform: translate(120%, 175%);
	}

	66.666% {
		transform: translate(-95%, 175%);
	}
}

@keyframes grow7132 {
	0%,
	100% {
		transform: scale(1.5);
	}

	20%,
	70% {
		transform: none;
	}
}


.loader {
	width: 48px;
	height: 48px;
	border-radius: 50%;
	display: inline-block;
	position: relative;
	border: 3px solid;
	border-color: var(--textColor) var(--textColor) transparent transparent;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}
.loader::after,
.loader::before {
	content: '';
	box-sizing: border-box;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	border: 3px solid;
	border-color: transparent transparent var(--primary) var(--primary);
	width: 40px;
	height: 40px;
	border-radius: 50%;
	box-sizing: border-box;
	animation: rotationBack 0.5s linear infinite;
	transform-origin: center center;
}
.loader::before {
	width: 32px;
	height: 32px;
	border-color: var(--textColor) var(--textColor) transparent transparent;
	animation: rotation 1.5s linear infinite;
}




.loader__sm {
	width: 28px;
	height: 28px;
}
.loader__sm::after,
.loader__sm::before {
	width: 20px;
	height: 20px;
}
.loader__sm::before {
	width: 12px;
	height: 12px;
}
@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
@keyframes rotationBack {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}
