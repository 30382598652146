@import 'vars';
.top__bar{
	padding: 10px;
	background: var(--light);
	display: flex;
	flex-direction: row;
	box-shadow: 0px 3px 6px 1px RGB(38 107 193 / 8%);

	.top__bar-plan{
		border-right: 3px solid var(--success);
		//background: var(--background);
		padding: 3px;
	}
	.top__bar-responses{
		border-right: 3px solid var(--success);
		//background: var(--background);
		padding: 3px;
	}

	.top__bar-userIcon{
		width: 55px;
		height: 55px;
		border-radius: 50%;
		margin-left: 10px;
	}
}