@use "../partials/vars";

.profile{
    .profile__actions{
        display: flex;
        flex-direction: row;
    }
    .profile__add-user{
        width: 50px;
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: RGBA(var(--success-rgb) / 90%);
        color: var(--secondaryTextColor);
        font-weight: bold;
        border: 2px solid var(--textColor);
        position: relative;
        cursor: pointer;
        margin-right: 10px;
    
        &:hover {
            background: RGBA(var(--success-rgb) / 120%);
        }
    }
    .profile__icon-notification {
        position: absolute;
        right: -3px;
        bottom: -7px;
        height: 25px;
        width: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: var(--danger);
        color: var(--secondaryTextColor)
    }
        .profile__icon-notificationRelative {
            position: relative;
            margin-left: auto;
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            top: 0px;
        }
    .profile__modal{
        display: flex !important;
        flex-direction: row !important;
        
        .profile__sidebar {
            flex-basis: 200px;
            border-right: 1px solid RGBA(var(--textColor-rgb) / 10%);

            .profile__sidebar-item{
                padding: 10px 15px;
                width: 100%;
                font-size: 13px;
                cursor: pointer;
                position: relative;

                .profile__icon-notification{
                    right: 10px;
                    top:  7px;
                }
                .profile__sidebar-itemDelete {
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    width: 30px;
                    height: 30px;
                    display: none;
                    z-index: 10;
                }
                &:hover{
                    background: RGBA(var(--primary-rgb) / 10%);

                    .profile__sidebar-itemDelete {
                        display: inline-block;

                        &:hover{
                            opacity: 0.5;
                        }
                    }
                }
                
            }
            .profile__sidebar-itemActive{
                background: RGBA(var(--primary-rgb) / 20%);
                border-left: 3px solid var(--primary);
                font-weight: bold;
                &:hover {
                    background: RGBA(var(--primary-rgb) / 20%);
                }
            }
        }
        .profile__sidebar-accounts{
            flex-basis: 250px !important;
            margin-left: -17px;
        }
        .profile__content{
            flex-basis: calc(100% - 240px);
            margin-left: 20px;
            min-height: 50vh;
        }
        .profile__add-role{
            width: 620px;
            max-width: 100%;
            margin: auto;
            padding: 10px;
            border-radius: var(--radius);
        }
    }
    .role__request-accepted{
        td{
            &:nth-child(1){
                border-left: 2px solid var(--success) !important;
                border-radius: 0px;
            }
        } 
        
    }
    .role__request-canceled {
        td{
            &:nth-child(1){
                border-left: 2px solid var(--warning) !important;
                border-radius: 0px;
            }
        } 
        
    }
    .role__request-rejected {
        td{
            &:nth-child(1){
                border-left: 2px solid var(--danger) !important;
                border-radius: 0px;
            }
        } 
        
    }
    .requests__email {
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.profile__icon {
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: RGBA(var(--primary-rgb) / 70%);
    color: var(--secondaryTextColor);
    font-weight: bold;
    border: 2px solid var(--textColor);
    position: relative;
    cursor: pointer;

    &:hover {
        background: RGBA(var(--primary-rgb) / 120%);
    }

    .profile__icon-open {
        position: absolute;
        right: -3px;
        bottom: -7px;
        height: 25px;
        width: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: RGBA(var(--primary-rgb) / 70%);
    }
}
.profile__icon-sm{
    width: 25px;
    height: 25px;
    font-size: 11px;
}