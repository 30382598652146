@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&family=Roboto&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slim-select/1.27.0/slimselect.min.css');

@tailwind utilities;

@import "partials/vars";
@import "partials/buttons";
@import "partials/forms";
@import "partials/typography";
@import "partials/helpers";
@import "partials/table";
@import "partials/misc";
@import "partials/dropdown";
@import "partials/tabs";
@import "partials/responsive";
@import "partials/modal";
@import "partials/cards";

.material-symbols-outlined {
	font-variation-settings:
	'FILL' 0,
	'wght' 400,
	'GRAD' 0,
	'opsz' 24
}

*{
	box-sizing: border-box;
}
html{
	height: 100%;
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}
body{
	font-size: 15px;
	font-family: 'Poppins', 'Roboto', sans-serif;
	display: flex;
	flex-direction: column;
	height: 100%;
	background: var(--background);
	color: var(--textColor);
	width: 100%;
	overflow: hidden;
	overflow-y: auto;
	padding: 0rem;
	margin: 0rem;
	font-weight: normal;
}
hr{
	border-color: RGBA(var(--textColor-rgb) / 10%);
}
#root{
	height: 100%;
}
.App{
	height: 100%;
}
span{
	display: inline-block;
}
a{
	text-decoration: none;
}
.text__hr{
	width: 90%;
	margin: auto;
	margin-top: 30px;
	margin-bottom: 30px;
	height: 15px;
	opacity: 0.8;
	border-bottom: 1px solid var(--primary);
	text-align: center;

	.text__hr-title{
		background-color: var(--light);
		padding: 0 10px;
	}
}
.colorpicker__wrap{
	position: relative;
	width: 35px;
	height: 35px;

	.colorpicker__dropdown{
		border: 2px solid RGBA(var(--textColor-rgb) / 10%);
		background: var(--secondary);
		padding: 15px;
	}
	.colorpicker__reset{
		position: absolute;
		right: 0px;
		top: 0px;
		width: 15px;
		height: 15px;
		font-size: 16px;
		background: var(--danger);
		border-radius: 4px;
		z-index: 99;
	}
	.colorpicker{
		position: relative;

		.colorpicker__color{
			width: 35px;
			height: 35px;
			border-radius: 6px;
			border: 1px solid RGBA( var(--textColor-rgb) / 10%);
			background: var(--background);
			cursor: pointer;

			&:hover{
				opacity: 0.7;
			}
		}
		.colorpicker__picker{
			max-width: 100%;
			padding: 5px 5px;
			border-radius: 6px;
		}
	}
	.colorpicker__picker-recent {
		max-height: 110px;
		overflow-y: auto;
	}
	input{
		height: 35px !important;
		width: 100px;
		vertical-align: middle;
	}
	.react-colorful__input{
		background: var(--secondary);
		padding: 10px;
	}
}
.image__upload-placeholder{
	width: 100%;
	max-height: 100%;
	border: 2px dashed RGBA(var(--textColor-rgb) / 30%);
	border-radius: var(--radius);
	text-align: center;
	position: relative;
	color: RGBA(var(--textColor-rgb) / 30%);
	align-items: center;
	display: flex;
	justify-content: center;
	overflow: hidden;

	.material-icons{
		font-size: 50px;
	}

	input{
		opacity: 0;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 90;
		//height: $height - 20px;
		margin: 0;
		padding: 0;
		display: block;
		cursor: pointer;
		width: 100%;
	}

	.image__delete{
		position: absolute;
		right: 5px;
		top: 5px;
		color: var(--danger);
		font-size: 25px;
		z-index: 91;
	}
	img{
		max-height: 100%;
	}
	.image__upload-info{
		position: absolute;
		left: 0;
		bottom: 0;
		background: RGBA(var(--primary-rgb) / 60% );
		color: var(--secondaryTextColor);
		width: 100%;
		padding: 4px;
		font-size: 12px;
		display: none;
	}
	&:hover{
		border-color: var(--primary);
		color: var(--primary);
		cursor: pointer;

		.image__upload-info{
			display: block;
		}
	}	
}
.imageChoice__elementOptions{
	img{
		max-height: 400px;
	}
}
.sortable__container{
	list-style-type: none;
	margin-left: -40px;
}
.sortable__inline{
	margin-top: -1px;

	li{
		display: inline-block !important;
	}
}

/* MOVE TO APP */
.ql-align-right{
	text-align: right;
}
.ql-align-left{
	text-align: left;
}
.ql-align-center{
	text-align: center;
}

.character__count{
	text-align: right;
}
.react-datetime-picker{
	width: 100%;
}
.react-datetime-picker__wrapper{
	width: 100%;
	border-color: RGBA( var(--textColor-rgb) / 20%);
	color: var(--textColor);
	border-radius: var(--radius);

	input{
		border-color: transparent;
	}
}
.timezone__select{
	.css-13cymwt-control{
		border-color: RGBA( var(--textColor-rgb) / 20%);
		color: var(--textColor);
		border-radius: var(--radius);
		background: transparent;
		height:45px;
	}
	.css-1u9des2-indicatorSeparator{
		height: 30px;
		background-color: RGBA(var(--textColor-rgb) / 10%);
	}
	.css-1xc3v61-indicatorContainer{
		height: 45px;
	}
	.css-t3ipsp-control{
		background: transparent;

		.css-1dimb5e-singleValue{
			color: var(--textColor) !important;
		}
	}
}
.card__secondary{
	padding: 10px;
	border-radius: var(--radius);
	background: var(--secondary);
}
.logo{
	width: 170px;
	height: 50px;
	background: var(--logo);
	background-repeat: no-repeat;
	background-size: contain;
}
.logo__white{
	width: 170px;
	height: 50px;
	background: var(--logoWhite);
	background-repeat: no-repeat;
	background-size: contain;
}
.logo__dark{
	width: 170px;
	height: 50px;
	background: var(--logoBlack);
	background-repeat: no-repeat;
	background-size: contain;
}
.active__roles-dropdown{
	padding-top: 14px !important;
	width: 220px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.logo__campaign-list{
	vertical-align: middle;
	margin-bottom: -20px;
	height: 60px !important;
}
.user__notVerified{
	width: 100%;
	padding: 20px;
	text-align: center;
	background-color: var(--warning);
	color: var(--secondaryTextColor);
}
.ck-content{
	border: 1px solid RGBA( var(--textColor-rgb) / 10%) !important;
}

.pulse__blob {
	background: var(--primary);
	border-radius: 50%;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	height: 10px;
	width: 10px;
	transform: scale(1);
	animation: pulse-primary 2s infinite;
}
.pulse__blob-danger {
	background: var(--danger);
}
@keyframes pulse-primary {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 RGBA(var(--primary-rgb) / 70%);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px RGBA(var(--primary-rgb) / 10%);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 RGBA(var(--primary-rgb) / 0%);
	}
}
@keyframes pulse-danger {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 RGBA(var(--danger-rgb) / 70%);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px RGBA(var(--danger-rgb) / 10%);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 RGBA(var(--danger-rgb) / 0%);
	}
}
.horizontalslider{
    width: 100%;
    display: flex;
    flex-direction: row;

    .tabs{
        width: calc(100% - 40px);
    }
    .arrow__move{
        font-size: 40px;
        margin-top: 10px;
        color: RGBA(var(--textColor-rgb) / 50%)
    }
}