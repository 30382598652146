@use "../partials/vars";

.poll__option{
    display: flex;
    flex-direction: row;
    padding: 0px;
    width: 100%;
    border: 1px solid RGBA(var(--primary-rgb) / 30%);
    border-radius: var(--radius);
    margin: 3px;
    position: relative;

    .poll__option-actions{
        flex-basis: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-right: 1px solid RGBA(var(--textColor-rgb) / 10%);
        margin-right: 5px;

        .poll__option-action{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 15px;
            opacity: 0.7;
            cursor: pointer;

            &:hover{
                opacity: 1;
            }
        }
        .poll__option-delete{
            position: relative !important;
            right: unset;
            top: unset;
        }
    }
    .poll__option-content {
        flex-basis: 70%;
        position: relative;
        margin-bottom: 20px;

        .poll__option-add {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
    
        .poll__option-attr {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 95% !important;
            border: 1px solid RGBA(var(--textColor-rgb) / 10%);
            overflow: hidden;
            border-radius: 25px;
            //background-color: RGBA(var(--primary-rgb) / 20%);
    
            input {
                background-color: transparent;
            }
    
            .poll__option-attrInner {
                max-width: 45%;
            }
        }
        .poll__option-attrAdd {
            background-color: RGBA(var(--primary-rgb) / 10%);
        }
    }
    .poll__option-delete{
        position: absolute;
        right: 10px;
        top: 8px;
    }
    div{
        width: 90% !important;
        
        input{
            border: none !important;
        }
    }
}
.image__choice-options{
    display: flex;
    flex-direction: column;

    .poll__option {
            display: flex;
            flex-direction: row;
            width: 100%;
            max-width: 100%;
            place-items: center;
            position: relative;
            padding-right: 2px;

            .poll__option-img {
                flex-basis: 200px;

                .image__upload-placeholder{
                    border: none;
                    width: 100% !important;
                }
            }
            .poll__option-delete{
                position: absolute;
                bottom: 10px;
                right: 2px;
                top: unset;
                visibility: hidden;
            }
            &:hover{
                .poll__option-delete{
                    visibility: visible;
                }
            }
            div {
                width: 100% !important;

                input {
                    border: none !important;
                }
            }
        }
}
