@use "../partials/vars";

.cms__registration {
    background: url('../../images/fancy-crave-qowyMze7jqg-unsplash.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    color: var(--textColor);

    .cms__registration__content {
        flex-basis: 100%;
        -webkit-backdrop-filter: blur(14px);
        backdrop-filter: blur(14px);
        padding: 15px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        .cms__registration__body {
            width: 600px;
            max-width: 100%;
            padding: 25px;
            background-color: var(--background);
            position: relative;
            border-radius: var(--radius);
        }
    }
}