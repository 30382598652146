@use "../partials/vars";

.campaign{
	min-height: 100%;
	width: 100%;
	position: relative;

	.campaign__tint{
		min-height: 100%;
		width: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		//background: RGBA(var(--textColor-rgb) / 80%);
	}
	.campaign__wrap{
		min-height: 100%;
		width: 768px;
		max-width: 100%;
		margin: auto;
		padding-bottom: 80px;
		padding-top: 1px;
	}
}
.campaign__edit{
	.campaign__edit-pages{
		width: 100%;
		border-top: 1px solid RGBA(var(--textColor-rgb) / 10%);
		background: var(--transparent);
		padding: 4px 5px;
		text-align:center;
		display: flex;
		flex-direction: row;
	}
 	.campaign__edit-content{
		display: flex;
		flex-direction: row;
		margin-top: 10px;

		.campaign__edit-contentSidebar{
			flex-basis: 200px;
			height: 85vh;
			display: flex;
			flex-direction: column;
			margin-top: -53px;

			.campaign__edit-pagesWrapper{
				position: relative;
			}

			.campaign__pages-container{
				width: 100%;
				height: 88vh;
				padding-bottom: 20px;
				overflow-y: auto;
				overflow-x: visible
			}

			.campaign__edit-page{
				width: 100%;
				height: auto;
				min-height: 90px;
				padding-top: 20px;
				padding-bottom: 20px;
				margin: 5px 3px;
				background-color: var(--light);
				border: 2px solid RGBA(var(--textColor-rgb) / 10%);
				color: var(--textColor);
				font-weight: 550;
				text-align: center;
				font-size: 12px;
				cursor: pointer;
				position: relative;
				border-radius: var(--radius);

				.campaign__edit-pageNumber{
					position: absolute;
					left: -1px;
					top: -2px;
					background: var(--secondary);
					font-size: 12px;
					padding: 3px 10px;
					border-bottom: 1px dashed var(--primary);
					border-right: 1px dashed var(--primary);
				}
				.campaign__edit-pageActions{
					position: absolute;
					bottom: 0;
					left: 0;
					display: flex;
					flex-direction: row;
					width: 100%;
					padding: 3px;
					display: none;
				}
				&:hover{
					.campaign__edit-pageActions{
						display: flex;
					}
				}
			}
			.campaign__edit-pageDanger{
				background: RGB(var(--danger-rgb) / 10%);
				margin-top: -10px;
				width: 97%;
			}
			.campaign__edit-pageActive{
				border: 2px solid var(--primary);
				background: RGBA(var(--primary-rgb)/ 20%);
				color: var(--primary);
				box-shadow: 0px 3px 6px 1px RGB(var(--primary-rgb) / 30%);
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
			}
			.campaign__edit-pageEnd{
				background: RGBA(var(--warning-rgb)/ 20%);
			}
		}
		.campaign__edit-contentLeft{
			flex-basis: 25%;
			background: var(--light);
			padding: 10px;
			margin: 5px;
			border-radius: 5px;
			max-height: 85vh;
			overflow-y: auto;

			.campaign__edit-elements{
				display: flex;
				flex-direction: row;
				margin-top: 10px;
				justify-content: center;
				flex-wrap: wrap;
				width: 100%;

				.campaign__edit-element{
					min-width: 150px;
					padding: 20px 10px;
					background: var(--secondary);
					text-align: center;
					border: 2px solid transparent;
					cursor: pointer;
					margin: 3px;
					position: relative;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;

					.material-icons{
						font-size: 40px;
						color: RGBA(var(--primary-rgb) / 70%);
					}

					.campaign__edit-elementOverlay{
						display: none;
						width: 100%;
						height: 100%;
						left: 0;
						top: 0;
						position: absolute;
						background: RGBA(var(--primary-rgb) / 80%);
						font-size: 50px;
						padding-top: 10px;
					}
					&:hover{
						border: 2px solid var(--primary);

						.campaign__edit-elementOverlay{
							display: block;
						}
					}
				}
			}
		}
		.campaign__edit-contentCenter{
			flex-basis: 25%;
			background: var(--light);
			padding: 10px;
			margin: 5px;
			border-radius: 5px;
		}
		.campaign__edit-contentRight{
			flex-basis: 41%;
			background: var(--light);
			padding: 10px;
			margin: 5px;
			height: 85vh;
			overflow-y: auto;
			border-radius: 5px;
			position: relative;
		}
	}

	.image__upload{
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;

		.image__upload-desktop{
			flex-basis: 65%;
			margin: 10px;

			.image__upload-placeholder{
				height: 300px;
				img{
					max-height: 300px;
				}
			}
		}
		.image__upload-mobile{
			width: 25%;
			margin: 10px auto;

			.image__upload-placeholder{
				height: 300px;
				img{
					max-height: 290px;
				}
			}
		}
	}
	.appearance__item{
		padding: 10px;
		border: 1px solid RGBA(var(--textColor-rgb) / 10%);
		position: relative;

		.appearance__color-item{
			position: absolute;
			right: 5px;
			top: 5px;
		}
	}
	.campaign__edit-preview{
		border: 1px solid RGBA(var(--textColor-rgb) / 10%);
		height: 74vh;
		position: relative;
	}
	.campaign__edit-previewMobile{
		width: 390px;
		height: 60vh;
		margin: auto;
	}
	.registration {
		width: 100% !important;
	}
	.campaign__edit-desktop{
		height: 100%;
	}
	.campaign__preview-elementActive{
		border-left: 4px solid var(--primary);
		background: RGBA(var(--primary-rgb) / 25%);

		.campaign__preview-elementDelete{
			display: block;
		}

		.campaign__preview-elementToolbar{
			display: block !important;
		}
	}
	.campaign__preview-element{
		width: 100%;
		padding: 10px;
		border: 2px solid transparent;
		margin: 10px 0px;
		border-radius: 2px;
		position: relative;
		cursor: pointer;
		min-height: 80px;

		.campaign__preview-overlay{
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			z-index: 98;
			position: absolute;
		}

		.campaign__preview-elementToolbar{
			display: none;
			position: absolute;
			left: 0px;
			top: 0px;
			width: 40px;
			height: 100%;
			background: RGBA(var(--light-rgb) / 80%);
			cursor: pointer;
			z-index: 99;
			text-align: center;

			.item{
				margin: 5px;
				cursor: pointer;

				&:hover{
					opacity: 0.8;
				}
			}
		}
		&:hover{
			border-left: 4px solid var(--primary);
			background: RGBA(var(--primary-rgb) / 50%);

			.campaign__preview-elementToolbar{
				display: block;
			}
		}
	}
	.campaign__preview-elementActive{
		border-color: var(--primary);
		box-shadow: 0px 3px 6px 1px RGB(var(--primary-rgb) / 50%);
	}
	.campaign__option{
		padding: 10px;
		border: 1px solid RGBA(var(--textColor-rgb) / 10%);
		position: relative;
		border-radius: var(--radius);

		.campaign__option-act{
			margin-top: 10px;
		}

		.campaign__option-color{
			position: absolute;
			right: 5px;
			top: 5px;
		}
	}
	.campaign__option-50{
		flex-basis: 49%;

		@media only screen and (max-width: 768px) {
			flex-basis: 49%;
		}
	}
	.ql-toolbar.ql-snow{
		border: 1px solid RGBA(var(--textColor-rgb) / 10%);
	}
	.ql-container.ql-snow{
		border: 1px solid RGBA(var(--textColor-rgb) / 10%);
	}
	.numeric__option{
		display: flex;
		flex-direction: row;
		padding: 10px;
		border-radius: var(--radius);
		margin: 5px;
		background: var(--secondary);
		position: relative;

		.numeric__option-left{
			flex-basis: 40%
		}
		.numeric__option-right{
			flex-basis: 60%	;

			.numeric__option-text{
				position: absolute;
				left: 42%;
				bottom: 10px;
			}
			.numeric__option-delete{
				position: absolute;
				right: 10px;
				top: 10px;
			}
		}
	}
	.socialelement{
		background: var(--background);
		padding: 5px;
		display: flex;
		flex-direction: row;
		border-radius: var(--radius);
		margin: 5px 0px;

		.socialelement__icon{
			width: 45px;
		}
	}
	.registration__element{
		background: var(--background);
		padding: 5px;
		border-radius: var(--radius);
		border: 2px dashed var(--primary);
		margin: 5px 0px;
		position: relative;
		padding-top: 30px;;

		.registration__element-inp{
			flex-basis: 80%;
			
		}
		.md__input{
			margin-top: 10px;
			flex-basis: 85%
		}
		.registration__field-type {
			padding: 3px 10px;
			position: absolute;
			left: -2px;
			top: -2px;
			border-bottom-right-radius: 4px;
			border-top-left-radius: 4px;;
			background: var(--primary);
			color: var(--secondaryTextColor);
			font-size: 10px;
		}
		.registration__form-fieldOptions{
			padding: 10px;
			margin: 10px 0px;
			border: 1px solid RGBA( var(--textColor-rgb) / 20%);
		}
		.registration__form-fieldOption{
			margin-left: 10px;
			font-size: 13px;
			background: var(--background);
			border-radius: var(--radius);
			border: 2px solid RGBA(var(--primary-rgb) / 25%);
			margin: 5px 0px;
			position: relative;
			display: flex;
			flex-direction: row;

			div{
				flex-basis: calc(100% - 50px);
				vertical-align: middle;;
			}
			input{
				border: none;
			}

			.delete{
				display: none;
				color: var(--danger);
				flex-basis: 30px;
				cursor: pointer;
				vertical-align: middle;
				margin-top: 10px;;
			}

			&:hover{
				.delete{
					display: inline-block;;
				}
			}
		}
		.registration__delete{
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background-color: RGBA(var(--background-rgb) / 90%);
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
		}
	}
	.registration__add-option{
		flex-basis: 45%;
		padding: 20px 10px;
		background: var(--secondary);
		text-align: center;
		border: 2px solid transparent;
		cursor: pointer;
		margin: 3px;
		position: relative;
		border-radius: 5px;
	}
}
.settings__item{
	padding: 10px;
}
.preview__loader{
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background: RGBA(var(--light-rgb) / 10%);
	text-align: center;
	z-index: 999;
	padding-top: 80px;
}
.code__card{
	padding: 10px;
	border-radius: var(--radius);
	background: var(--secondary);
	position: relative;

	.code__card-copy{
		position: absolute;
		right: 5px;
		top: 5px;
	}
}
.code__card-copied{
	background: RGBA(var(--success-rgb) / 40%);
}
.card__check-option{
	flex-basis: 31%;
	margin-top: 20px;
	margin: 5px;
	padding: 10px;
	border-radius: var(--radius);
	border: 1px solid transparent;
	position: relative;
	border: 1px solid RGBA(var(--textColor-rgb) / 10%);
	text-align: center;

	&:hover{
		border: 1px solid var(--primary);
	}
}
.card__check-radio{
	width: 30px;
	height: 30px;
	border-radius: 50%;
	text-align: center;
	padding-top: 1px;
	background: var(--secondary);
	border: 1px solid var(--primary);
	position: absolute;
	right: -5px;
	top: -5px;
	color: var(--primary);
	z-index: 90;

	.material-icons{
		transform: rotate(-10deg);
		display: none;
	}
}
.card__check-optionActive {
	border-color: var(--primary);

	.card__check-radio{
		background: var(--primary);
		color: var(--secondaryTextColor);

		.material-icons{
			display: inline-block;
		}
	}
}
.categorized__poll-category{
	border: 2px dashed var(--primary) !important;
	margin-top: 10px;
}
.wheel__category-img{
	width: 100px;
	overflow: hidden;
	height: 100px;
}
.campaign__locked{
	position: relative;

	.locked{
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		z-index: 99;
		background: RGB(var(--background-rgb) / 70%);
		backdrop-filter: blur(12px);
		display: flex;
		flex-direction: column;
		height: 100%;
		width:100%;
		height:100%;
		padding-top: 70px;
	}
}
.css-13cymwt-control{
	border-color: RGBA( var(--textColor-rgb) / 20%);
	color: var(--textColor);
	border-radius: var(--radius);
	background: transparent;
	height: 50px;
}
.poweredBy__preview{
	position: absolute !important;
	bottom: 0;
	left: 0;
}
.top__bar{
	flex-direction: row;

	.topbar__name{
		position: relative;
	
		.editable__input-text{
			margin-top: 10px;
			span{
				overflow: hidden;	
				max-height: 45px;
				width: 180px;
				text-overflow: ellipsis;
				white-space: nowrap;
				
			}
		}
		.editable__input-inp {
			margin-top: -10px;
		}
		.character__count{
			font-size: 11px;
			position: absolute;
			top: -8px;
			right: 30px;
			background-color: var(--secondary);
			padding: 2px;
		}
	}
	@media(max-width: 570px){
		flex-direction: column;
		
		.top__bar-top{
			flex-basis: 100%;
		}
		.top__bar-bottom{
			flex-basis: 100%;
			margin-top: 4px;
			margin: 4px;
		}
		.topbar__name{
			width: 100%;
			.editable__input-text{
				width: 100%;

				span{
					width: 100%;
				}
			}
		}	
	}

}
.registration__react-select{
	display: flex;
	flex-direction: row;

	.PhoneInputCountry{
		pointer-events: none;

		.PhoneInputCountrySelectArrow{
			display: none;
		}
	}
	.PhoneInputInput{
		display: none;
	}
	.registration__react-selectDropdown{
		flex-basis: 90%;
	}
	.css-13cymwt-control{
		height: 40px;
		.css-qbdosj-Input{
			flex-basis: 100%;
			height: 35px;
		}
	}
}
.campaign__appearance{
	display: flex;
	flex-direction: column;
	padding: 10px;
	position: absolute;
	right: 0;
	top: 67px;
	z-index: 200;
	background-color: var(--light);
	width: 40%;
	height: 91%;
	overflow-y: auto;

	.campaign__appearance-header{
		display: flex;
		flex-direction: row;
		width: 100%;

		.close-modal{
			margin-left: auto;
		}
		svg {
			width: 3em !important;
			height: 3em;
			cursor: pointer !important;
	
			path {
				fill: var(--textColor);
			}
		}
	
		&:hover {
			path {
				fill: var(--danger) !important;
			}
		}

	}
	.campaign__appearance-color{
		display: flex;
		flex-direction: row;
		width: 100%;
		height: 100px;
		margin: 10px;
		border: 1px RGBA( var(--textColor-rgb) / 20%);

		.campaign__appearance-colorLeft{
			flex-basis: 110px;
			.colorpicker__color-lg {
				width: 100px !important;
				height: 100px !important;
				display: flex;
				flex-direction: column;
				place-items: center;

				
			}
			.colorpicker__reset {
				left: 0px !important;
			}
		}
		.campaign__appearance-colorRight {
			flex-basis: calc(100% - 110px);
		}
		
	}
}
.topbar__actionItem{
	@media(max-width: 1100px){
		display: none;
	}
}
.page__dropdown-action{
	margin-left: -80px;
}