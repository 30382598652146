.poweredBy{
	z-index: 999;
	background: var(--app-light);
	border-radius: 4px;
	width: 768px;
	text-align: center;
	padding: 4px;
    max-width: 100%;
	color: RGBA(var(--app-textColor-rgb) / 60%);
}
.poweredByStatic{
    position: fixed;
	left: 50%;
  	transform: translate(-50%, -50%);
    bottom: -18px;
}
.logo__powered{
	width: 120px !important;
	height: 25px !important;
	margin-top: 5px;
	display: inline-block;
	vertical-align: middle;
}