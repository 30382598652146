@use "../partials/vars";

.campaign__list{
	margin: 10px;
	padding: 15px;
	overflow: hidden;
	height: calc(100% - 100px);

	.campaign__list-actions{
		.campaign__list-actionItem{
			margin-top: 5px;
		}
	}
	.campaign__list-actionsDesk{
		@media(max-width: 970px) {
			display: none !important;
		}
	}
	.campaign__list-row{
		@media(max-width: 970px) {
			td{
				border-color: transparent;
			}
		}
	}
	.campaign__list-actionsMob{
		display: none !important;
		@media(max-width: 970px) {
			display: table-row !important;
			width: 100%;
		}
	}
	.campaign__list-tableHeader{
		@media(max-width: 810px) {
			display: none !important;
		}
	}
	.campaign__list-create{
		@media(max-width: 470px) {
			.campaign__list-createText{
				display: none;
			}
			.material-icons{
				font-weight: bold;
			}	
		}
	}
	.campaign___list-organizationsMob{
		display: none !important;
	}
	@media(max-width: 700px) {
		padding: 3px;
		.campaign__list-accounts{
			display: none;
		}
		.campaign__list-list{
			flex-basis: 100%;
			margin-left: 0px;
		}
		.campaign__list-rowName{
			width: 60%;
		}
		.campaign___list-organizationsMob{
			display: block !important;

			.campaign__list-accounts{
				display: flex;
			}
		}
	}
}
.campaign__list-list {
	flex-basis: 100%;
	background: var(--light);
	padding: 10px 0px;
	padding-top: 0px;
	overflow-y: auto;
}

.campaign__list-accounts {
	flex-basis: 250px;
	background: var(--light);
	border-radius: 10px;
	margin-top: 10px;
	overflow-y: auto;

	.campaign__list-accountsItem{
		width: 100%;
		padding: 13px 15px;
		cursor: pointer;
		display: flex;
		flex-direction: row;
		position: relative;
	}
	.campaign__list-accountsItemActive{
		background: RGBA(var(--primary-rgb) / 40%);
		border-left: 3px solid var(--primary);
	}
	.campaign__list-accountsItemText{
		width: 200px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		margin-left: 5px;
		

		.campaign__list-accountsItemOptions{
			position: absolute;
			right: 0px;
			top: 10px;
		}
		&:hover{
			.campaign__list-accountsItemOptions {
				display: inline-block;
			}
		}
	}
}

.campaign__list-listMd {
	flex-basis: calc(100% - 260px);
	margin-left: 10px;
}
.campaign__types{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	

	.campaign__types-left{
		flex-basis: 40%;
		max-height: 50vh;
		padding: 10px;
		overflow-y: auto;
		overflow-x: hidden;

		.campaign__types-type{
			width: 100%;
			padding: 10px 5px;
			background: var(--secondary);
			text-align: center;
			border: 2px solid transparent;
			cursor: pointer;
			margin: 3px;
			position: relative;
			display: flex;
			flex-direction: row;
			align-content: center;
		
			.material-icons {
				font-size: 30px;
				vertical-align: middle;
				color: RGBA(var(--primary-rgb) / 70%);
			}
		
			.campaign__edit-elementOverlay {
				display: none;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				position: absolute;
				background: RGBA(var(--primary-rgb) / 80%);
				font-size: 20px;
				padding-top: 10px;
			}
		
			&:hover {
				border: 2px solid var(--primary);
		
				.campaign__edit-elementOverlay {
					display: block;
				}
			}
		}
		.campaign__type-typeActive{
			border: 2px solid var(--primary);
			background: RGBA(var(--primary-rgb) / 40%);
		}
	}
	.campaign__types-right{
		flex-basis: 60%;
		padding: 15px;
	}
}