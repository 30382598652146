@use "../partials/vars";

.login {
    background: url('../../images/fancy-crave-qowyMze7jqg-unsplash.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    
    .login__content{
        width: 100%;
        -webkit-backdrop-filter: blur(14px);
        backdrop-filter: blur(14px);
        padding: 15px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        
        .login__body{
            width: 600px;
            max-width: 100%;
            padding: 25px;
            background-color: var(--background);
            position: relative;
            border-radius: var(--radius);
            
            .themeswitch {
                position: absolute;
                right: 10px;
                top: 10px;
            }
        }
    }
}
.twofa__input{
    letter-spacing: 33px;
    text-align: center;
    font-size: 35px !important;
    padding-top: 8px;
    height: 60px !important;

    &::placeholder {
        font-size: 35px;
        margin-top: 15px;
    }
}