@use "../partials/vars";

.soccer {
	width: 100%;
	position: relative;
	padding: 20px;
	
	.soccer__results-container {
		width: 768px;
		max-width: 100%;
		margin: auto;
		
		.soccer__results-containerInner {
			border-left: 1px solid RGBA(var(--textColor-rgb) / 20%);
			width: 100%;
			background-color: var(--light);
			padding-top: 20px;
			padding-bottom: 20px;
			border-radius: var(--radius);
			
			.soccer__result {
				width: 100%;
				display: flex;
				flex-direction: column;
				text-align: left;
				margin-top: 25px;
				
				.soccer__result-text {
					margin-left: 10px;
				}
			}
		}
		
		.soccer__results-totalVotes {
			position: absolute;
			right: 15px;
			top: 0px;
			font-size: 35px;
			
			.material-icons {
				font-size: 45px;
				vertical-align: middle;
			}
		}
	}
	
	.stage {
		position: relative;
		width: 100%;
		margin: auto;
		height: 100%;
		top: 0;
		z-index: 1;
		backface-visibility: hidden;
	}
	
	.world {
		position: relative;
		top: 0;
		left: 0;
		width: 500px;
		height: 700px;
		margin: auto;
		max-width: 100%;
		transform-style: preserve-3d;
		z-index: 1;
		backface-visibility: hidden;

		@media(max-width: 450px) {
			height: 52vh;
		}
	}
	
	.team {
		position: relative;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transform-style: preserve-3d;
	}
	
	.terrain {
		position: relative;
		top: 0;
		left: 0;
		width: 100%;
		margin: auto;
		height: 100%;
		transform-style: inherit;
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
	}
	
	.field {
		position: relative;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #eee;
		z-index: 2;
		//transform-origin: 50% 50%;
		box-sizing: content-box;
		backface-visibility: hidden;
	}
	
	.field__texture {
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 3;
	}
	
	.field__texture.field__texture--gradient {
		background-image: linear-gradient(to top, rgba(0, 0, 0, 0.2), transparent);
		z-index: 4;
	}
	
	.flipped .field__texture.field__texture--gradient {
		opacity: 0;
	}
	
	.field__texture.field__texture--gradient-b {
		opacity: 0;
		background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), transparent);
		z-index: 4;
	}
	
	.flipped .field__texture.field__texture--gradient-b {
		opacity: 1;
	}
	
	.texture .field__texture.field__texture--grass {
		background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/215059/grass2.png");
		background-repeat: repeat;
		background-size: 75px 75px;
		background-position: -20px -20px;
	}
	
	.field__line {
		position: absolute;
		width: 100%;
		height: 3px;
		z-index: 4;
	}
	
	.field__line--goal {
		width: 16%;
		height: 6%;
		border: solid 3px rgba(255, 255, 255, 0.5);
		border-bottom: none;
		left: 0;
		right: 0;
		margin: auto;
		bottom: 4%;
	}
	
	.field__line--goal--far {
		top: 4%;
		bottom: auto;
		border: solid 3px rgba(255, 255, 255, 0.5);
		border-top: none;
	}
	
	.field__line--penalty-arc {
		width: 20%;
		height: 20%;
		overflow: hidden;
		bottom: 20%;
		left: 0;
		right: 0;
		margin: auto;
	}
	
	.field__line--penalty-arc:after {
		position: absolute;
		top: 75%;
		width: 100%;
		height: 100%;
		left: 0;
		content: ' ';
		display: block;
		border-radius: 50% 50% 0 0;
		border: solid 3px rgba(255, 255, 255, 0.5);
		border-bottom: none;
		box-sizing: border-box;
	}
	
	.field__line--penalty-arc--far {
		bottom: auto;
		top: 20%;
	}
	
	.field__line--penalty-arc--far:after {
		bottom: 75%;
		top: auto;
		border: solid 3px rgba(255, 255, 255, 0.5);
		border-top: none;
		border-radius: 0 0 50% 50%;
	}
	
	.field__line--penalty {
		width: 44%;
		height: 16%;
		border: solid 3px rgba(255, 255, 255, 0.5);
		border-bottom: none;
		left: 0;
		right: 0;
		margin: auto;
		bottom: 4%;
	}
	
	.field__line--penalty--far {
		top: 4%;
		bottom: auto;
		border: solid 3px rgba(255, 255, 255, 0.5);
		border-top: none;
	}
	
	.field__line--outline {
		width: 92%;
		height: 92%;
		top: 4%;
		left: 4%;
		border: solid 3px rgba(255, 255, 255, 0.5);
	}
	
	.field__line--mid {
		top: 50%;
		width: 92%;
		left: 4%;
		background-color: rgba(255, 255, 255, 0.5);
	}
	
	.field__line--circle {
		width: 21%;
		height: 13%;
		top: 4px;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		border: solid 3px rgba(255, 255, 255, 0.5);
		border-radius: 50%;
	}
	
	.field__player-name {
		padding: 2px;
		border-radius: 4px;
		background-color: RGBA(var(--app-secondary-rgb) / 50%);
		-webkit-backdrop-filter: blur(14px);
		backdrop-filter: blur(14px);
		z-index: 100;
		margin-top: -10px;
	}
	
	.formation__grid {
		width: 100%;
		height: 93%;
		margin-top: 30px;
		position: absolute;
		z-index: 99;
		display: flex;
		flex-direction: column;
		
		.formation__grid-row {
			height: 20%;
			display: flex;
			flex-direction: column;
			
			.formation__grid-rowSub {
				display: flex;
				flex-direction: row;
				justify-content: center;
				flex-basis: 50%;
				//height: 50%;
				//border: 1px solid yellow;
				
				.formation__grid-column {
					flex-grow: 1;
					max-height: 120px;
					height: 100%;
					//border: 1px solid blue;
					text-align: center;
					padding-top: 15px;
					overflow: hidden;
				}
				
				.lineup__player {
					position: relative;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					background: RGBA(var(--app-primary-rgb) / 50%);
					height: 150px;
					width: 70px;
					border-radius: 10px;
					
					i {
						font-size: 40px !important;
					}
					
					img {
						max-width: 60px;
						max-height: 70px;
						position: relative;
					}
					
					&:hover {
						background: RGBA(var(--app-primary-rgb) / 50%);
					}
					.lineup__bar {
						background-color: var(--primary);
						position: absolute;
						bottom: 0;
						left: 0;
						z-index: -1;
						width: 100%;
						height: 80%;
						//border-top: 6px solid #FFF;
						box-sizing: border-box;
						animation: grow 1.5s ease-out forwards;
						transform-origin: bottom;
					}
					.percentage {
						position: absolute;
						top: 0;
						left: 0;
						margin-left: auto;
						right: 0;
						margin-right: auto;
						font-weight: bold;
						color: var(--textColor);
					}
					@media(max-width: 450px) {
						height: 75px !important;
						width: 50px;
					}					
					@keyframes grow {
						from {
							transform: scaleY(0);
						}
					}
				}
			}
		}
	}
	.soccer__lineup{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		.soccer__lineup-position {
			display: grid;
			grid-template-columns: 23px 23px 23px 23px 23px;
			grid-gap: 10px;
			place-items: center;
			width: 100%;
			text-align: center;
		}
		.soccer__player {
			width: 100%;
			// background-color: RGBA(var(--app-primary-rgb) / 20%);
			margin: 10px;
			border-radius: var(--radius);
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			margin-left: 20px;
			
			.soccer__player-side{
				display: flex;
				align-items: center;
				width: 107px;
				margin-right: 10px;
			}
			.soccer__player-name{
				-webkit-backdrop-filter: blur(14px);
				backdrop-filter: blur(14px);
				z-index: 2;
				margin-top: -10px;
				width: 100%;
				padding: 5px;
				padding-left: 10px;
				text-align: left;
				font-weight: bold;
			}
			.bar__chart {
				position: relative;
				
				.bar__chart-bar {
					position: relative;
					
					.bar__chart-pollVotes {
						position: absolute;
						bottom: -25px;
						right: 5px;
						color: RGBA(var(--textColor-rgb) / 50%);
					}
				}
			}
		}
	}
	@media(max-width: 768px){
        .tabs{
            .tabs__item{
                font-size: 13px !important;
            }
        }
    }
	@media(max-width: 600px){
        padding-top: 70px !important;
    }
}
