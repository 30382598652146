@use "../partials/vars";

.incomingRequests {
	background: var(--background);
	height: 100%;
	width: 100%;
	position: relative;
	color: var(--textColor);
	
	.incomingRequests__inner{
		background: url('../../images/magicpattern-grid-pattern-1682066435460.png');
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;
		color: var(--textColor);
		
		.incomingRequests__content {
			width: 600px;
			max-width: 100%;
			-webkit-backdrop-filter: blur(14px);
			backdrop-filter: blur(14px);
			padding: 15px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: relative;
		}
	}
}