@use "../partials/vars";

.wheel__cms {
	.wheel__colors{
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-wrap: wrap;

		.wheel__color {
			width: 100px;
			margin: 10px;
			display: flex;
			flex-direction: row;
		}
	}
	.wheel__slice{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
		padding: 10px;
		margin-top: 10px;
		border-radius: var(--radius);
		border: 1px solid RGBA(var(--textColor-rgb) / 20%);
		background-color: var(--secondary);
		position: relative;

		.wheel__slice-icon{
			position: absolute;
			left: 0;
			top: 0;
			background-color: var(--secondary);
			
			.btn{
				width: 30px;
				height: 30px;
				border-radius: 50%;
				padding-top: 8px;
			}
		}
		.drag__handle{
			position: absolute;
			right: 0px;
			top: 10px;
			padding: 7px 0px;
			border-radius: 3px;
			opacity: 0.4;
		}
		.wheel__slice-image{
			flex-basis: 110px;
		}
		.wheel__slice-content{
			flex-basis: calc(100% - 120px);
			display: flex;
			flex-direction: column;

			.wheel__slice-contentSection{
				padding: 10px;
				width: 100%;
			}
			.wheel__slice-contentText {
				width: 100%;
			}
			.wheel__slice-contentColors{
				width: 100%;
				margin-top: 10px;
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
			}

		}
		.wheel__slice-remove{
			position: absolute;
			right: 0px;
			top: 50px;
			color: var(--danger);
			cursor: pointer;
			opacity: 0.4;
		}
		&:hover{
			.wheel__slice-remove{
				display: flex;
				opacity: 1;
			}
			.drag__handle{
				opacity: 1;
			}
		}
	}
}
.wheel__slice-contentPrizes{
	border: 1px dashed RGBA(var(--textColor-rgb) / 10%);
	padding: 10px;
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 20px;
	border-radius: var(--radius);

	.wheel__slice-contentPrizesWrap{
		margin-top: 10px;
		border-top: 1px solid RGBA(var(--textColor-rgb) / 10%);
		padding: 10px;
		overflow-y: auto;

		.wheel__slice-prize{
			display: flex;
			flex-direction: column;
			background-color: var(--secondary);
			padding: 10px;
			border-radius: var(--radius);
			border: 1px dashed RGBA(var(--textColor-rgb) / 10%);
			margin-top: 10px;
		}
		.wheel__slice-prizeStrategies{
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			.wheel__slice-prizeStrategiesItem{
				flex-basis: 45%;
				margin: 3px;
				padding: 1px;
				text-align: center;
				border-radius: 4px;
				cursor: pointer;
			}
			.wheel__slice-prizeStrategiesItemActive{
				background: var(--primary);
				color: var(--secondaryTextColor);
				border-radius: 4px;
			}
		}

		.wheel__slice-contentPrize{
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;

			.wheel__slice-contentPrizeImage{
				width: 70px;
				height: 70px;

				img {
					max-width: 50px;
				}
			}
			.wheel__slice-contentPrizeText{
				flex-basis: calc(100% - 170px);
				margin-left: 10px;
			}
			.wheel__slice-contentPrizeActions {
				flex-basis: 30px;
				display: flex;
				flex-direction: column;
				margin-left: auto;
				opacity: 0.4;
			}
			&:hover{
				.wheel__slice-contentPrizeActions {
					opacity: 1;
				}
			}
		}
	}
}
.wheel__prize-date {
	background-color: var(--light);
	padding: 10px;
	margin-top: 10px;
	border-radius: var(--radius);
	position: relative;

	.wheel__prize-dateDelete{
		position: absolute;
		right: 10px;
		top: 0px;
		cursor: pointer;
	}
}
.wheel__prize-distribute{
	margin: 5px !important;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}